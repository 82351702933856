<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">经营分析</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp a-mlr-17" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <!-- <le-input-district-choose label="省市区" 
                    :province.sync="pageParam.params.provinceCode" 
                    :city.sync="pageParam.params.cityCode" 
                    :area.sync="pageParam.params.areaCode"></le-input-district-choose> -->
                <le-company-under-select label="所属商户" v-model="pageParam.params.companyId"></le-company-under-select>
                <le-date-range label="时间" ref="dateRangeCompany" :minDate.sync="pageParam.params.startDay" :maxDate.sync="pageParam.params.endDay" :clearable="false" />
            </le-search-form>
            <le-pagview ref="businessAnalysisBusiness" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.businessAnalysisBusinessList" :pageSizeDefault='10'>
                <el-table ref="businessAnalysisBusinessList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="companyName" label="商户名称" min-width="110">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue font-point" @click="toBusinessAnalysisInfo(row)">{{ row.companyName?row.companyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="总营业额(元)" min-width="168">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs a-w-100" style="align-items: flex-start !important;">
                                <span>总营收：￥{{ row.allTransactionAmount?(row.allTransactionAmount/100).toFixed(2):0 }}</span>
                                <span>退款：￥{{ row.allRefundAmount?(row.allRefundAmount/100).toFixed(2):0 }}</span>
                                <span>实收：￥{{ util.numFormat(row.allTransactionAmount - row.allRefundAmount) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="累计收益(元)" min-width="180">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start !important">
                                <span>总收益：￥{{ row.allIncomeAmount?(row.allIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>套餐收益：￥{{ row.allMonthCardIncomeAmount?(row.allMonthCardIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电桩收益：￥{{ row.allChargingIncomeAmount?(row.allChargingIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电柜收益：￥{{ row.allBoxIncomeAmount?(row.allBoxIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电舱收益：￥{{ util.numFormat(row.allRoomIncomeAmount) }}</span>
                                <span>毛豆消耗：{{ (row.allPoints || 0) - (row.allPointsRefund || 0) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="搜索时间范围内营业额" min-width="180">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs a-w-100" style="align-items: flex-start !important;">
                                <span>总营收：￥{{ row.timeTransaction?(row.timeTransaction/100).toFixed(2):0 }}</span>
                                <span>退款：￥{{ row.timeRefundAmount?(row.timeRefundAmount/100).toFixed(2):0 }}</span>
                                <span>实收：￥{{ util.numFormat(row.timeTransaction - row.timeRefundAmount) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="搜索时间范围内收益" min-width="180">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start !important">
                                <span>总收益：￥{{ row.timeIncomeAmount?(row.timeIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>套餐收益：￥{{ row.monthCardIncomeAmount?(row.monthCardIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电桩收益：￥{{ row.chargingIncomeAmount?(row.chargingIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电柜收益：￥{{ row.boxIncomeAmount?(row.boxIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电舱收益：￥{{ util.numFormat(row.roomIncomeAmount) }}</span>
                                <span>毛豆消耗：{{ (row.points || 0) - (row.pointsRefund || 0) }}</span>
                                <!-- <span>水机收益：￥{{ row.waterIncomeAmount?(row.waterIncomeAmount/100).toFixed(2):0 }}</span> -->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="历史单插槽收益" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ row.hisPerDeviceAmount?(row.hisPerDeviceAmount/100).toFixed(2):0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineStatus" label="搜索时间范围内单插槽收益" min-width="180">
                        <template slot-scope="{ row }">
                            <span>￥{{ row.timePerDeviceAmount?(row.timePerDeviceAmount/100).toFixed(2):0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="单插槽使用率" min-width="152">
                        <template slot-scope="{ row }">
                            <span>{{ row.slotUsingRationHis || 0 }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="单插槽使用率" min-width="152">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>单插槽使用率</span>
                                <span class="a-fs-12 a-c-999">搜索时间内</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>{{ row.slotUsingRationTime || 0 }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="设备（在线数/总数）" min-width="160">
                        <template slot-scope="{ row }">
                            <span class="a-c-green">{{ row.onlineNum?row.onlineNum:0 }}</span>
                            <span>/{{ row.deviceNum?row.deviceNum:0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="正在充电插槽数" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.useNum?row.useNum:0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toBusinessAnalysisInfo(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal">条结果</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getAnalysisBusinessPage,
                    method: "post",
                    params: {
                        companyId: '',
                        incomeId: '',
                        stationId: '',
                        provinceCode: '',
                        cityCode: '',
                        areaCode: '',
                        startDay: this.$getDay.getTodayBeforeDays(30),
                        endDay: this.$getDay.getTodayBeforeDays(0)
                    },
                    freshCtrl: 1,
                },
                optionsStatIncome:[{
                    label: "自营",
                    value: 0,
                }, {
                    label: "参与分成",
                    value: 1,
                }],
                fileUrl: '',//文件导出url
            }
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    companyId: '',
                    incomeId: '',
                    stationId: '',
                    provenceCode: '',
                    cityCode: '',
                    areaCode: '',
                    startDay: this.$getDay.getTodayBeforeDays(30),
                    endDay: this.$getDay.getTodayBeforeDays(0)
                };
                this.$refs['dateRangeCompany'].value1 = [this.$getDay.getTodayBeforeDays(30),this.$getDay.getTodayBeforeDays(0)]
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['businessAnalysisBusiness'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            toBusinessAnalysisInfo (datas) {
                this.$router.push({
                    path: '/businessAnalysis/businessAnalysis-info',
                    query: {
                        id: datas.companyId,
                        title: datas.companyName,
                        type: 'business',
                        revenueStatistics: JSON.stringify(datas)
                    }
                })
            },
            exportfile () {
                this.$exfile({
                    code: 8,
                    fileName: this.pageParam.params.startDay + ' 至 ' + this.pageParam.params.endDay + ' 商户经营分析',
                    startTime: this.pageParam.params.startDay,
                    endTime: this.pageParam.params.endDay,
                    params: this.pageParam.params
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
/deep/ .s-search-label{
    width: 100px
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>